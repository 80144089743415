var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(
      (_vm.competencia_de || _vm.competencia_ate) && _vm.temas_selecionados.length > 0
    )?_c('v-row',{staticClass:"mx-auto",attrs:{"justify":"center"}},[_c('div',{staticClass:"mt-2 body-1 font-weight-bold"},[_vm._v(" Há "),_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.qtdIndicadoresErros))]),_vm._v(" indicador(es) com erros de validação ")])]):_vm._e(),(_vm.competencia_de || _vm.competencia_ate)?_c('v-row',{staticClass:"mx-auto"},[_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"row-cursor-pointer",attrs:{"multi-sort":"","dense":"","headers":_vm.headers,"items":_vm.filteredItems,"options":{ multiSort: false },"loading-text":"Carregando tabela","headerProps":{ sortByText: 'Ordenar Por' },"footer-props":{
          itemsPerPageText: 'Itens por Página',
          itemsPerPageOptions: [10, 25, 50, 100],
        },"no-data-text":"Nenhum registro disponível","no-results-text":"Nenhum registro correspondente encontrado","mobile-breakpoint":10},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([_vm._l((_vm.chavesIndicadores.concat(
            _vm.indicadoresLiberados
          )),function(headerValue){return {key:_vm.getHeaderSlotName(headerValue),fn:function(item){return [_c('span',{key:headerValue},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.header.text)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.descricaoPorIndicador[headerValue])+" ")])])],1)]}}}),{key:"item.serv_005",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.serv_005)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.nomesServicos[item.serv_005])+" ")])])]}},{key:"item.status_aprovacoes",fn:function(ref){
          var item = ref.item;
return [_c('span',{class:{
                NA: 'font-weight-medium',
                Validado: 'success--text font-weight-bold',
                'Em Análise': 'info--text font-weight-bold',
                'Reprovado - Retificar': 'error--text font-weight-bold',
              }[_vm.statusAprovacoes[item.serv_005]]},[_vm._v(" "+_vm._s(_vm.statusAprovacoes[item.serv_005])+" ")])]}},_vm._l((_vm.indicadoresLiberados),function(indicador){return {key:_vm.getItemSlotName(indicador),fn:function(ref){
              var item = ref.item;
return [_c('span',{key:indicador},[(item[("validos_" + indicador)] > 0)?_c('v-chip',{staticClass:"mb-1 mr-1",attrs:{"small":"","outlined":"","color":"success"}},[_c('span',{staticClass:"mr-1 font-weight-black",attrs:{"left":""}},[_vm._v(" "+_vm._s(item[("validos_" + indicador)])+" ")]),_vm._v(" OK ")]):_vm._e(),(item[("alertas_" + indicador)] > 0)?_c('v-chip',{staticClass:"mb-1 mr-1",attrs:{"small":"","outlined":"","color":"warning"}},[_c('span',{staticClass:"mr-1 font-weight-black",attrs:{"left":""}},[_vm._v(" "+_vm._s(item[("alertas_" + indicador)])+" ")]),_vm._v(" Alertas ")]):_vm._e(),(item[("erros_criticos_" + indicador)] > 0)?_c('v-chip',{staticClass:"mb-1 mr-1",attrs:{"small":"","color":"error"}},[_c('span',{staticClass:"mr-1 font-weight-black",attrs:{"left":""}},[_vm._v(" "+_vm._s(item[("erros_criticos_" + indicador)])+" ")]),_vm._v(" Erros ")]):_vm._e()],1)]}}})],null,true)})],1)],1):_vm._e(),_c('servicos-v2-visao-detalhada-dialog-detalhes',{ref:"dialogDetalhes",attrs:{"competencia_de":_vm.competencia_de,"competencia_ate":_vm.competencia_ate,"temas_selecionados":_vm.temas_selecionados,"chavesIndicadores":_vm.chavesIndicadores,"indicadoresPorTema":_vm.indicadoresPorTema,"indicadoresLiberados":_vm.indicadoresLiberados,"authorizedServicos":_vm.authorizedServicos,"descricaoPorIndicador":_vm.descricaoPorIndicador,"authorizedCompanies":_vm.authorizedCompanies,"nomesServicos":_vm.nomesServicos},on:{"updateLoadingDados":function($event){return _vm.$emit('updateLoadingDados', $event)},"updateDataTable":function($event){return _vm.$emit('updateDataTable', $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }