<template>
  <span>
    <v-row
      v-if="
        (competencia_de || competencia_ate) && temas_selecionados.length > 0
      "
      class="mx-auto"
      justify="center"
    >
      <div class="mt-2 body-1 font-weight-bold">
        Há
        <span class="error--text">{{ qtdIndicadoresErros }}</span> indicador(es)
        com erros de validação
      </div>
    </v-row>

    <v-row class="mx-auto" v-if="competencia_de || competencia_ate">
      <v-col cols="12" class="mt-2">
        <v-data-table
          multi-sort
          dense
          :headers="headers"
          :items="filteredItems"
          :options="{ multiSort: false }"
          loading-text="Carregando tabela"
          :headerProps="{ sortByText: 'Ordenar Por' }"
          :footer-props="{
            itemsPerPageText: 'Itens por Página',
            itemsPerPageOptions: [10, 25, 50, 100],
          }"
          no-data-text="Nenhum registro disponível"
          no-results-text="Nenhum registro correspondente encontrado"
          :mobile-breakpoint="10"
          class="row-cursor-pointer"
          @click:row="handleClick"
        >
          <template
            v-for="headerValue in chavesIndicadores.concat(
              indicadoresLiberados
            )"
            v-slot:[getHeaderSlotName(headerValue)]="item"
          >
            <span :key="headerValue">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    {{ item.header.text }}
                  </span>
                </template>
                <span>
                  {{ descricaoPorIndicador[headerValue] }}
                </span>
              </v-tooltip>
            </span>
          </template>
          <template v-slot:[`item.serv_005`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  {{ item.serv_005 }}
                </span>
              </template>
              <span>
                {{ nomesServicos[item.serv_005] }}
              </span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.status_aprovacoes`]="{ item }">
            <span
              :class="
                {
                  NA: 'font-weight-medium',
                  Validado: 'success--text font-weight-bold',
                  'Em Análise': 'info--text font-weight-bold',
                  'Reprovado - Retificar': 'error--text font-weight-bold',
                }[statusAprovacoes[item.serv_005]]
              "
            >
              {{ statusAprovacoes[item.serv_005] }}
            </span>
          </template>
          <template
            v-for="indicador in indicadoresLiberados"
            v-slot:[getItemSlotName(indicador)]="{ item }"
          >
            <span :key="indicador">
              <v-chip
                v-if="item[`validos_${indicador}`] > 0"
                small
                outlined
                class="mb-1 mr-1"
                color="success"
              >
                <span class="mr-1 font-weight-black" left>
                  {{ item[`validos_${indicador}`] }}
                </span>
                OK
              </v-chip>
              <v-chip
                v-if="item[`alertas_${indicador}`] > 0"
                small
                outlined
                class="mb-1 mr-1"
                color="warning"
              >
                <span class="mr-1 font-weight-black" left>
                  {{ item[`alertas_${indicador}`] }}
                </span>
                Alertas
              </v-chip>
              <v-chip
                v-if="item[`erros_criticos_${indicador}`] > 0"
                small
                class="mb-1 mr-1"
                color="error"
              >
                <span class="mr-1 font-weight-black" left>
                  {{ item[`erros_criticos_${indicador}`] }}
                </span>
                Erros
              </v-chip>
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <servicos-v2-visao-detalhada-dialog-detalhes
      ref="dialogDetalhes"
      :competencia_de="competencia_de"
      :competencia_ate="competencia_ate"
      :temas_selecionados="temas_selecionados"
      :chavesIndicadores="chavesIndicadores"
      :indicadoresPorTema="indicadoresPorTema"
      :indicadoresLiberados="indicadoresLiberados"
      :authorizedServicos="authorizedServicos"
      :descricaoPorIndicador="descricaoPorIndicador"
      :authorizedCompanies="authorizedCompanies"
      :nomesServicos="nomesServicos"
      @updateLoadingDados="$emit('updateLoadingDados', $event)"
      @updateDataTable="$emit('updateDataTable', $event)"
    />
  </span>
</template>

<script>
  import IndicadoresServicosV2Service from "@/services/IndicadoresServicosV2Service.js";

  export default {
    name: "ServicosV2VisaoDetalhadaFalhas",
    components: {
      ServicosV2VisaoDetalhadaDialogDetalhes: () =>
        import(
          "@/components/validacoes/ServicosV2VisaoDetalhadaDialogDetalhes.vue"
        ),
    },
    props: {
      competencia_de: {
        type: String,
        required: true,
      },
      competencia_ate: {
        type: String,
        required: true,
      },
      temas_selecionados: {
        type: Array,
        required: true,
      },
      servicos_selecionados: {
        type: Array,
        required: true,
      },
      chavesIndicadores: {
        type: Array,
        required: true,
      },
      indicadoresPorTema: {
        type: Object,
        required: true,
      },
      indicadoresLiberados: {
        type: Array,
        required: true,
      },
      authorizedServicos: {
        type: Array,
        required: false,
      },
      authorizedCompanies: {
        type: Array,
        required: false,
      },
      descricaoPorIndicador: {
        type: Object,
        required: true,
      },
      nomesServicos: {
        type: Object,
        required: true,
      },
      mostrarApenasErros: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      headers: [],
      items: [],
      statusAprovacoes: {},
    }),
    computed: {
      filteredItems() {
        let items = JSON.parse(JSON.stringify(this.items));

        if (this.mostrarApenasErros == true) {
          items = items.filter((item) => item.erros > 0);
        }

        return items;
      },
      qtdIndicadoresErros() {
        let qtdErros = 0;

        this.indicadoresLiberados.forEach((indicador) => {
          this.items.forEach((item) => {
            qtdErros += item[`erros_criticos_${indicador}`];
          });
        });

        return qtdErros;
      },
    },
    mounted() {
      this.getDados();
    },
    methods: {
      getDados(
        competencia_de = this.competencia_de,
        competencia_ate = this.competencia_ate,
        temas_selecionados = this.temas_selecionados,
        servicos_selecionados = this.servicos_selecionados
      ) {
        this.$emit("updateLoadingDados", true);

        Promise.all([
          this.getQtdTiposValidacaoGroupByServico(
            competencia_de,
            competencia_ate,
            temas_selecionados,
            servicos_selecionados
          ),
          this.getStatusAprovacoesServicos(
            competencia_de,
            competencia_ate,
            temas_selecionados,
            servicos_selecionados
          ),
        ])
          .catch((err) => {
            console.error(err);
          })
          .finally(() => this.$emit("updateLoadingDados", false));
      },
      getQtdTiposValidacaoGroupByServico(
        competencia_de,
        competencia_ate,
        temas_selecionados,
        servicos_selecionados
      ) {
        return IndicadoresServicosV2Service.getQtdTiposValidacaoGroupByServico(
          competencia_de,
          competencia_ate,
          temas_selecionados.join(","),
          servicos_selecionados.join(",")
        )
          .then((res) => {
            this.setHeaders();
            this.items = res;
          })
          .catch((err) => {
            this.$toast.error("Erro ao recuperar dados das validações.", "", {
              position: "topRight",
              timeout: 5000,
            });
            throw err;
          })
          .finally(() => {
            // this.$emit("updateLoadingDados", false);
            this.temas_selecionados_is_dirty = false;
          });
      },
      setHeaders() {
        this.headers = [
          { text: "Serviço", value: "serv_005" },
          { text: "Aprovações", value: "status_aprovacoes" },
        ];

        this.indicadoresLiberados.forEach((indicador) => {
          this.headers.push({
            text: indicador.toUpperCase(),
            value: indicador,
          });
        });
      },
      getStatusAprovacoesServicos(
        competencia_de,
        competencia_ate,
        temas_selecionados,
        servicos_selecionados
      ) {
        return IndicadoresServicosV2Service.getStatusAprovacoesServicos(
          competencia_de,
          competencia_ate,
          temas_selecionados.join(","),
          servicos_selecionados.join(",")
        )
          .then((res) => {
            this.statusAprovacoes = res;
          })
          .catch((err) => {
            this.$toast.error("Erro ao recuperar status das aprovações.", "", {
              position: "topRight",
            });
            throw err;
          });
      },
      getHeaderSlotName(name) {
        return `header.${name}`;
      },
      getItemSlotName(indicador) {
        return `item.${indicador}`;
      },
      handleClick(items) {
        this.$refs.dialogDetalhes.getDadosDialogDetalhes(items.serv_005);
      },
    },
  };
</script>

<style>
  .row-cursor-pointer tbody tr :hover {
    cursor: pointer;
  }

  .v-data-table table thead tr th {
    font-weight: 300;
    font-size: 14.5px;
    margin: 0px 0px;
    padding-left: 8px;
    padding-right: 24px;
  }

  .v-data-table table tbody tr td {
    font-size: 13px;
  }
</style>
